import { keyHandler } from "@jmc/utils/utils/keyHandler";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { LightBox } from "@jmc/solid-design-system/src/components/molecules/LightBox/LightBox";
import { mdiFullscreen } from "@mdi/js";
import classnames from "classnames";
import React, { ReactNode, useState } from "react";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

import style from "./style.module.scss";

export type ImageHorizontalAlignmentType = "start" | "end" | "center" | "not-scale-down";

interface WrapperPropTypes {
    id?: string;
    children: JSX.Element;
    caption?: string | ReactNode;
    enableLightBox: boolean;
    disableIcon?: boolean;
    onClick?: () => void;
    horizontalAlignment?: ImageHorizontalAlignmentType;
    url: string;
    sizing?: {
        width?: string;
        maxWidth: string;
        maxHeight: string;
    };
    enlargeLabel?: string;
}

/**
 * ImageWrapper Component adds a caption and Lightbox functionality to any Image Component you pass it as a child.
 * Typically used to wrap the Img component from gatsby-image plugin.
 */

export const ImageWrapper: React.FunctionComponent<WrapperPropTypes> = ({
    id,
    caption,
    enableLightBox,
    disableIcon = false,
    onClick,
    children,
    horizontalAlignment = "center",
    url,
    sizing = { width: "100%", maxWidth: "100%", maxHeight: "100%" },
    enlargeLabel = null,
}: WrapperPropTypes) => {
    const [showLightBox, setShowLightBox] = useState(false);
    const { jnjFullBranded } = useJnjBranding();

    const openLightBox = () => {
        if (enableLightBox) {
            setShowLightBox(true);
        }
    };

    const onClickImage = () => {
        if (onClick) {
            onClick();
        } else {
            openLightBox();
        }
    };

    return (
        <div
            className={classnames(
                style.element,
                enableLightBox && !showLightBox ? style.clickable : null,
                horizontalAlignment === "not-scale-down" ? null : style[`${horizontalAlignment.toLowerCase()}Align`],
            )}
            data-test-id={id}
        >
            {/*We need to wrap the image with this this div, because there is no onClick support on the Img, making React.cloneElement unusable. */}
            <div
                className={style.imageWrapper}
                onClick={onClickImage}
                onKeyDown={(e: KeyboardEvent): void => keyHandler(e.key, () => onClickImage())}
                style={sizing}
                role={enableLightBox && !disableIcon ? "none" : "button"}
                tabIndex={enableLightBox && !disableIcon ? "" : 0}
            >
                {children}
                {enableLightBox && !disableIcon && (
                    <button
                        className={style.lightBoxIcon}
                        onClick={openLightBox}
                        tabIndex={0}
                        aria-label={enlargeLabel}
                        title={enlargeLabel}
                    >
                        <Icon icon={mdiFullscreen} size="large" color="inherit" />
                    </button>
                )}
            </div>
            {caption && (
                <Typography variant="body" weight="400" size="s" className={jnjFullBranded ? style.figcaption : null}>
                    {caption}
                </Typography>
            )}
            {showLightBox && (
                <LightBox onClose={(): void => setShowLightBox(false)}>
                    <LightBox.Image url={url} title={children.props.title} />
                </LightBox>
            )}
        </div>
    );
};
export type ImageFitType = "cover" | "contain" | "fill" | "scale-down" | "none";
interface PropTypes {
    url: string;
    alt: string;
    caption?: string | ReactNode;
    title?: string;
    enableLightBox: boolean;
    disableIcon?: boolean;
    fit?: ImageFitType;
    height?: number;
    dimension?: { height: number; width: number };
    onClick?: () => void;
    horizontalAlignment?: ImageHorizontalAlignmentType;
    sizing?: {
        width?: string;
        maxWidth: string;
        maxHeight: string;
    };
    loading?: "eager" | "lazy";
    enlargeLabel?: string;
    className?: string;
}

/**
 * Image Component is a thin wrapper around <img> and mainly adds a caption and Lightbox functionality.
 */
export const Image: React.FunctionComponent<PropTypes> = ({
    url,
    alt = "",
    caption,
    title,
    enableLightBox,
    disableIcon = false,
    fit = "cover",
    height = 0,
    dimension,
    onClick,
    horizontalAlignment = "center",
    sizing = { width: "100%", maxWidth: "100%", maxHeight: "100%" },
    loading,
    enlargeLabel = null,
    className,
}: PropTypes) => {
    const [showLightBox, setShowLightBox] = useState(false);

    const openLightBox = () => {
        if (enableLightBox) {
            setShowLightBox(true);
        }
    };

    const onClickImage = () => {
        if (onClick) {
            onClick();
        } else {
            openLightBox();
        }
    };

    const isSvg = url?.endsWith(".svg");
    const isGif = url?.endsWith(".gif");

    let widthClass = "";
    if (isSvg && fit === "none") {
        // In JWM the SVG images will keep their defined dimensions.
        widthClass = style.setWidth;
    }
    if (isGif && (fit === "scale-down" || fit === "none")) {
        // GIF images will keep their defined dimensions in this mode1.
        widthClass = style.setWidth;
    }

    return (
        <div
            className={classnames(
                style.element,
                enableLightBox && !showLightBox ? style.clickable : null,
                horizontalAlignment === "not-scale-down" ? null : style[`${horizontalAlignment.toLowerCase()}Align`],
                className,
            )}
        >
            <div
                className={classnames(
                    style.svgImageWrapper,
                    isSvg && (fit === "scale-down" || fit === "cover" || fit === "contain" || fit === "none")
                        ? style.setWidth100Pct
                        : null, // SVG images will grow to full container size.
                )}
                onClick={onClickImage}
                onKeyDown={(e: KeyboardEvent): void => keyHandler(e.key, () => onClickImage())}
                role={enableLightBox && !disableIcon ? "none" : "button"}
                tabIndex={enableLightBox && !disableIcon ? "" : 0}
                style={sizing}
            >
                <img
                    src={url}
                    alt={alt || ""}
                    title={title}
                    className={classnames(
                        isSvg && fit === "scale-down" ? "" : style[fit], // scaling down svgs doesn't work well with CSS object-fit
                        widthClass,
                    )}
                    height={height || "auto"}
                    style={
                        (fit === "none" || fit === "scale-down") && dimension
                            ? { maxHeight: dimension.height, maxWidth: dimension.width }
                            : null
                    }
                    loading={loading ? loading : null}
                />
                {enableLightBox && !disableIcon && (
                    <button
                        className={style.lightBoxIcon}
                        onClick={openLightBox}
                        tabIndex={0}
                        aria-label={enlargeLabel}
                        title={enlargeLabel}
                    >
                        <Icon icon={mdiFullscreen} size="large" color="inherit" />
                    </button>
                )}
            </div>
            {caption && (
                <Typography variant="body" weight="400" size="s">
                    {caption}
                </Typography>
            )}
            {showLightBox && (
                <LightBox onClose={(): void => setShowLightBox(false)}>
                    <LightBox.Image url={url} title={title} />
                </LightBox>
            )}
        </div>
    );
};
